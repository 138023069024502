import React from "react";
import Header1 from "./Header1";
import BottomHeader from "./BottomHeader";
const Director = () => {
  return (
    <>
      <Header1 />
      <BottomHeader data={{ name: "Director Message" }} />

      {/* <div className="director">
        <div className="dir_img">
          <img src="./img/director.jpg" alt="" />
        </div>
        <div className="dir_msg">
          <h4>Director Message</h4>
          <p>
            Dear Parent & Students, It gives me great pleasure to welcome you to
            Blue Bells School. The school is committed to inculcating in all our
            students; strong ethical valued of integrity, respect and discipline
            as well as clarity in thought and decision-making ability, as a
            life-long process. In order to achieve all this, We follow a
            rigorous programme that is based upon the best educational
            practices, highly progressive and recognized world-wide; and one
            that creates conditions for students to maximize their potential at
            an internationally competitive level. Our state-of-the-art
            infrastructure, coupled with highly qualified and well-trained
            teachers, ensures that the students at Blue Bells School should be
            able to gain an enriching experience. Furthermore, it has been one
            of my quests in life to make high quality education available;
            accessible through the latest educational technology. The mission of
            the school is to develop responsible global citizens and leaders
            through academic excellence, We will be the foundation for a new
            generation of leaders and innovators, who will continue to pave the
            way for a better and brighter future for our world. The greatest
            strength of Blue Bells School is the high quality programmes and
            transparency in its system. We are fortunate to have a school
            community which includes talented teachers and supportive parents
            who work together to make Blue Bells School, an ideal place for our
            motivated students to learn and grow. We are always open to new
            ideas, inquiries and feedback, from all. Over the past 44 years,
            Blue Bells School has lived up to its mission statement and
            established itself as one of the top educational institutions for
            students and parents in Bhopal. I welcome you to be a part of Blue
            Bells School family!
          </p>
        </div>
      </div> */}
      <div className="princi">
        <div className="princi_left">
          <div className="pri">
            <img src="./img/plac.jpg" alt="" />
            <h5></h5>
          </div>
        </div>
        <div className="princi_right">
          <h4>Director Message</h4>
          <p>
            Dear Parent & Students, It gives me great pleasure to welcome you to
            Shri Ram Higher Secondary School  The school is committed to inculcating in all our
            students; strong ethical valued of integrity, respect and discipline
            as well as clarity in thought and decision-making ability, as a
            life-long process. In order to achieve all this, We follow a
            rigorous programme that is based upon the best educational
            practices, highly progressive and recognized world-wide; and one
            that creates conditions for students to maximize their potential at
            an internationally competitive level. Our state-of-the-art
            infrastructure, coupled with highly qualified and well-trained
            teachers, ensures that the students at Shri Ram Higher Secondary School  should be
            able to gain an enriching experience. Furthermore, it has been one
            of my quests in life to make high quality education available;
            accessible through the latest educational technology. The mission of
            the school is to develop responsible global citizens and leaders
            through academic excellence, We will be the foundation for a new
            generation of leaders and innovators, who will continue to pave the
            way for a better and brighter future for our world. The greatest
            strength of Shri Ram Higher Secondary School  is the high quality programmes and
            transparency in its system. We are fortunate to have a school
            community which includes talented teachers and supportive parents
            who work together to make Shri Ram Higher Secondary School , an ideal place for our
            motivated students to learn and grow. We are always open to new
            ideas, inquiries and feedback, from all. Over the past 44 years,
            Shri Ram Higher Secondary School  has lived up to its mission statement and
            established itself as one of the top educational institutions for
            students and parents in Bhopal. I welcome you to be a part of Shri Ram Higher Secondary School  family!
          </p>
        </div>
      </div>
    </>
  );
};

export default Director;
