import React, { useState } from 'react';
// import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
// import * as constants from "../contants";
import Modal from 'react-bootstrap/Modal';

const View = () => {

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [xmShow, setXmShow] = useState(false);
  const [xlShow, setXlShow] = useState(false);
  const [xsShow, setXsShow] = useState(false);

  // const { data } = useQuery(constants.Gallery);

  // console.log(data?.Gallery[0]?.Gallery_image, "Gallery-daaataaaaa");

  // const [currentImage, setCurrentImage] = useState(0);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);
  // const images = [
  //   "./img1/g1.jpeg",
  //   "./img1/g2.jpeg",
  //   "./img1/g3.jpeg",
  //   "./img1/g4.jpeg",
  //   "./img1/g5.jpeg",
  //   "./img1/g6.jpeg",
  //   "./img1/g7.jpeg",
  //   "./img1/g8.jpeg",
  // ];

  // const openImageViewer = useCallback((index) => {
  //   setCurrentImage(index);
  //   setIsViewerOpen(true);
  // }, []);

  // const closeImageViewer = () => {
  //   setCurrentImage(0);
  //   setIsViewerOpen(false);
  // };
  return (
    <>

      <div className="container3" data-aos-duration="2000">


        <div className="gallerimages">
          <div className="galleyig">
            <div onClick={() => setSmShow(true)} className="me-2">
              <img
                style={{
                  height: "12rem",
                  width: "12rem",
                  margin: "2rem",
                  border: "3px solid black"
                }}
                src="./img/gallery.jpeg" alt="" />
            </div>

            <div onClick={() => setLgShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/gallery0.jpeg" alt="" />
            </div>
            <div onClick={() => setXsShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/gallery1.jpeg" alt="" />
            </div>


          </div>

          <div className="galleyig">
            <div onClick={() => setMdShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/gallery2.jpeg" alt="" />
            </div>
            <div onClick={() => setXmShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/gallery3.jpeg" alt="" />
            </div>
            <div onClick={() => setXlShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/gallery4.jpeg" alt="" />
            </div>
          </div>
        </div>


      </div>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/gallery.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/gallery0.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>

      <Modal
        size="lg"
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/gallery2.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      <Modal
        size="lg"
        show={xmShow}
        onHide={() => setXmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/gallery3.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      <Modal
        size="sm"
        show={xlShow}
        onHide={() => setXlShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-sm">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/gallery4.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      <Modal
        size="sm"
        show={xsShow}
        onHide={() => setXsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/gallery1.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      {/* <div className="image-new">
        {data?.Gallery[0]?.Gallery_image.map((item, index) => (
          <img
            // src={src}
            src={`http://0.0.0.0:8055/assets/${item?.directus_files_id?.id}`}
            onClick={() => openImageViewer(index)}
            width="250"
            height="250"
            key={index}
            alt=""
          />
        ))}

        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div> */}
    </>
  );
};

export default View;
