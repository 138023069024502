import React from "react";
import Header1 from "./Header1";
import BottomHeader from "./BottomHeader";
import { AiFillStar } from "react-icons/ai";

const Admission = () => {
  return (
    <>
      <Header1 />
      <BottomHeader data={{ name: "Admission Processure" }} />

      <div className="addmision">
        <h4> Shri Ram Higher Secondary School:</h4>
        <div className="section1">
          <p>
            <h4>Registration:</h4>
          </p>
          <p>
            Parents are required to submit a non-refundable application form to
            the school.
          </p>
          <p>
            Registered parents are invited to visit the campus to understand the
            Goenkan culture & ethos.
          </p>

          <p>
            The mere act of Registration will not constitute a guaranteed
            admission, which is subject to interaction with Principal, Aptitude
            test (grade V & above) and the school rule book
          </p>
          <br />
          <p>
            <h4>
              Classes:{" "}
              <span style={{ fontSize: "1rem" }}> Nursery to 12th </span>
            </h4>
          </p>
          <br />
         <p>
            <h4>
              Subject:{" "}
              <span style={{ fontSize: "1rem" }}>
                {" "}
                PCB, PCM, ARTS, ACCOUNTS{" "}
              </span>
            </h4>
          </p>
          <br />
          <p>
            <h4>
              School Timing:{" "}
              <span style={{ fontSize: "1rem" }}>
                {" "}
                9:00 AM TO 3:00 PM (Monday to Saturday){" "}
              </span>
            </h4>
          </p>
          <br />
          <p>
            <h4>
              Medium:{" "}
              <span style={{ fontSize: "1rem" }}>
                {" "}
                English and Hindi Medium{" "}
              </span>
            </h4>
          </p>
          <br />
          <p>
            <h4>
              Board Code:{" "}
              <span style={{ fontSize: "1rem" }}> 632 043</span>
            </h4>
          </p>
          <br/>
          <p>
            <h4>
              Dise Code:{" "}
              <span style={{ fontSize: "1rem" }}> 23320403155</span>
            </h4>
          </p>
        </div>

        <div className="section2">
          <h4>Submission of Documents:</h4>
          <p>
            Following Registration, duly filled Application form needs to be
            submitted along with the documents listed below within 5 days of the
            registration date.
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Attested copy of Birth certificate
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Copy of Address proof
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            TC from previous school (class II onwards)
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />6 passport size
            photos of the child and 1 each of the parents
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Previous session report card for Nursery to class I or previous
            three session report cards (class II onwards), whichever applies.
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Vaccination card
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Blood group card or report
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Copy of Aadhar card
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Copy of Samagra ID
          </p>
          <p>
            <AiFillStar style={{ color: "rgb(239,68,68)" }} />
            Cheque Photocopy
          </p>
        </div>
      </div>
    </>
  );
};

export default Admission;
