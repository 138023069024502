//import React from "react"
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";


const Header1 = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [down, setDown] = useState(false);

  // const stickyHeader = useRef();
  // useLayoutEffect(() => {
  //   const navbar= document.getElementById("navbar");
  //   let fixedTop = stickyHeader.current.offsetTop;
  //   const fixedHeader = () => {
  //     if (window.pageYOffset > fixedTop) {
  //       navbar.classList.add("fixedTop");
  //     } else {
  //       navbar.classList.remove("fixedTop");
  //     }
  //   };
  //   window.addEventListener("scroll", fixedHeader);
  // }, []);

  return (
    <>
      <div className="top">
        <div className="topleft">
          <a href="/">Home</a>
        </div>
        <div className="right">
          <div className="first">
            <a href="mailto:shriramhss1978@gmail.com">
              shriramhss1978@gmail.com
            </a>
            <br />
            <a href="tel: 8962523839, 8358836350 ,  9424485443 ">
              8962523839 , 8358836350 ,  9424485443
            </a>
          </div>
          {/* <div className="second">
            <a href="....." >
              LOGIN
            </a>
          </div> */}
        </div>
      </div>

      <div className="header1">
        <div className="navleft">
          <img src="./img/Logo.png" alt="" />
        </div>
        <div className="navright h1-right">
          <ul>
            <li>
              <NavLink to="/" className="new">Home</NavLink>
            </li>
            <li className="new">
              <NavLink>ABOUT US</NavLink>
              <ul className="abouts">
                <li>
                  <NavLink to="/about" className="abouts1">
                    ABOUT US
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/director" className="abouts1">
                    DIRECTOR MESSAGE
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/management" className="abouts1">
                    MANAGEMENT MESSAGE
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/principal" className="abouts1">
                    PRINCIPAL MESSAGE
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="new">
              <NavLink>ACADEMICS</NavLink>
              <ul className="abouts">
                <li>
                  <NavLink to="/admission" className="abouts1">
                    ADMISSION PROCESSURE
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/facility" className="abouts1">
                    FACILITIES
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/gallery" className="new">
                GALLERY
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="new">
                CONTACT US
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to=""
                
                className="new"
              >
                LOGIN
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink className="new">HALL OF FAME</NavLink>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="mobile">
        <div className="mobile_top">
          <ul>
            <li>
              <a href="mailto:shriramhss1978@gmail.com">
                shriramhss1978@gmail.com
              </a>
            </li>

            <li>
              <a href="tel: 8962523839, 8358836350 ,  9424485443 ">
                8962523839 , 8358836350 ,  9424485443
              </a>
            </li>
          </ul>
          {/* <a href="...." >
            LOGIN
          </a> */}
        </div>

        <div className="mobile_top2">
          <img src="./img/Logo.png" alt="" />
          <div className="mobile_right">
            <FaBars
              style={{ color: "#ffff", fontSize: "2rem" }}
              onClick={() => setOpen(!open)}
            />

            {open ? (
              <div className="mob">
                <ul>
                  <li>
                    <NavLink to="/">HOME</NavLink>
                  </li>
                  <li className="new-1">
                    <NavLink>
                      ABOUT US
                      <IoMdArrowDropdown onClick={() => setShow(!show)} />
                    </NavLink>
                    {show ? (
                      <ul className="new1" onClick={() => setShow(!show)}>
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/about">ABOUT US</NavLink>
                        </li>
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/director">DIRECTOR MESSAGE</NavLink>
                        </li>
                        {/* <li onClick={() => setShow(!show)}>
                          <NavLink to="/management">MANAGEMENT MESSAGE</NavLink>
                        </li> */}
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/principal">PRINCIPAL MESSAGE</NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="new-1">
                    <NavLink>
                      ACADEMICS
                      <IoMdArrowDropdown onClick={() => setDown(!down)} />
                    </NavLink>
                    {down ? (
                      <ul className="new1" onClick={() => setDown(!down)}>
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/admission">
                            ADMISSION PROCESSURE
                          </NavLink>
                        </li>
                        <li onClick={() => setShow(!show)}>
                          <NavLink to="/facility">FACILITIES</NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    <NavLink to="/gallery">GALLERY</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">CONTACT</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/">LOGIN</NavLink>
                  </li> */}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header1;
