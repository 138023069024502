import React from "react";
import Header1 from "./Header1";
import BottomHeader from "./BottomHeader";

const Facilities = () => {
  return (
    <>
      <Header1 />
      <BottomHeader data={{ name: "Facilities" }} />

      <div className="facilities">
        <div className="facility">
          <h3>Facilities</h3>
        </div>

        {/* <div className="fac">
          <h3>
            Nice Infrastructure <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            The fact is that a good school infrastructure, with renewed spaces,
            makes it possible for children and youths that live in remote areas
            to study .A good school infrastructure makes it a good place for the
            children to study, which is important as the student spends maximum
            time in school.
          </p>

          <h3>
            Fitness & Yoga <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            Yoga at school might help students feel better and improve their
            grades, Hygiene, fitness and "Good Living Skill" taught to students.
          </p>

          <h3>
            Science Lab <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            The school has well equipped Biology, Physics and Chemistry labs
            where regular practicals are held.
          </p>

          <h3>
            Good Academic <img src="./img/star.gif" alt="" />{" "}
          </h3>
          <p>
            We provide our student with good encyclopedia to reduce their
            encumbrance regarding any subject academically or globally. A well
            equipped laboratories of physics, chemistry, biology and computers
            for practical learning .We teach students by smart classes for
            active participation and deep knowledge. A good psychiatrist and
            counselor for students and Parents is available during school
            timings.
          </p>

          <h3>
            Skilled Staff <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            Skilled Staff is here to teach or guide for your bright carrier and
            and provides Hands on in our School.Teachers play a key role in
            identifying and supporting students who are at risk of dropping out,
            especially students from vulnerable populations, such as girls .
          </p>

          <h3>
            Cultural Activity
            <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            The cultural activities enhance the confidence level for students,
            which allow students to perform better. Activities will develop the
            personality for students and assist the students for good career.
            Cultural learning manifests itself in three forms during human
            ontogeny: imitative learning, instructed learning, and collaborative
            learning.
          </p>

          <h3>
            Computer Lab <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            We have two full fledged computer labs one for primary and the other
            for higher section with Internet facility where every student in the
            assigned period gets a computer to work with.
          </p>

          <h3>
            Indoor / Out Door Sports <img src="./img/star.gif" alt="" />
          </h3>
          <p>
            Extensive and well maintained play fields of football and cricket
            and courts for volleyball, basketball, kabaddl, throwball and
            badminton me available to students. Indoor facilities of chess,
            caroms, table tennis etc. are also available.
          </p>
        </div> */}

        <div className="fac1">
          <img src="./img/r6.jpeg" alt="" />
          <div className="fac11">
            <h3>Infrastructure </h3>
            <p> The fact is that a good school infrastructure, with renewed spaces,
              makes it possible for children and youths that live in remote areas
              to study .A good school infrastructure makes it a good place for the
              children to study, which is important as the student spends maximum
              time in school.</p>
          </div>
        </div>
        <div className="fac1">
          <div className="fac11">
            <h3>Fitness & Yoga</h3>
            <p>  Yoga at school might help students feel better and improve their
              grades, Hygiene, fitness and "Good Living Skill" taught to students.</p>
          </div>
          <img src="./img1/f1.jpg" alt="" />
        </div>

        <div className="fac1">
          <img src="./img1/f1.jpg" alt="" />
          <div className="fac11">
            <h3> Science Lab</h3>
            <p> The school has well equipped Biology, Physics and Chemistry labs
              where regular practicals are held.</p>
          </div>
        </div>
        <div className="fac1">
          <div className="fac11">
            <h3> Good Academic</h3>
            <p>  We provide our student with good encyclopedia to reduce their
              encumbrance regarding any subject academically or globally. A well
              equipped laboratories of physics, chemistry, biology and computers
              for practical learning .We teach students by smart classes for
              active participation and deep knowledge. A good psychiatrist and
              counselor for students and Parents is available during school
              timings.</p>
          </div>
          <img src="./img/gallery3.jpeg" alt="" />
        </div>
        {/* <div className="fac1">
          <img src="./img1/f1.jpg" alt="" />
          <div className="fac11">
            <h3> Skilled Staff</h3>
            <p> Skilled Staff is here to teach or guide for your bright carrier and
              and provides Hands on in our School.Teachers play a key role in
              identifying and supporting students who are at risk of dropping out,
              especially students from vulnerable populations, such as girls .</p>
          </div>
        </div>
        <div className="fac1">
          <div className="fac11">
            <h3> Cultural Activity</h3>
            <p>The cultural activities enhance the confidence level for students,
              which allow students to perform better. Activities will develop the
              personality for students and assist the students for good career.
              Cultural learning manifests itself in three forms during human
              ontogeny: imitative learning, instructed learning, and collaborative
              learning.</p>
          </div>
          <img src="./img1/f1.jpg" alt="" />
        </div>
        <div className="fac1">
          <img src="./img1/f1.jpg" alt="" />
          <div className="fac11">
            <h3> Computer Lab</h3>
            <p>   We have two full fledged computer labs one for primary and the other
              for higher section with Internet facility where every student in the
              assigned period gets a computer to work with.</p>
          </div>
        </div>
        <div className="fac1">
          <div className="fac11">
            <h3>Indoor / Out Door Sports</h3>
            <p>  Extensive and well maintained play fields of football and cricket
              and courts for volleyball, basketball, kabaddl, throwball and
              badminton me available to students. Indoor facilities of chess,
              caroms, table tennis etc. are also available.</p>
          </div>
          <img src="./img1/f1.jpg" alt="" />
        </div> */}
      </div>
    </>
  );
};

export default Facilities;
