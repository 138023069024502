import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Header1 from "./Header1";
import BottomHeader from "./BottomHeader";
import ImageViewer from "./ImageViewer";
import './Gallery.css';

const Gallery = () => {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [xmShow, setXmShow] = useState(false);
  const [xlShow, setXlShow] = useState(false);
  const [xsShow, setXsShow] = useState(false);

  return (
    <>
      <Header1 />
      <BottomHeader data={{ name: "Gallery" }} />

      <div className="heading3">
        <h4>GALLERY</h4>
      </div>
      <ImageViewer />
      <div className="container3 " data-aos-duration="2000">
        {/* <ImageViewer /> */}

        <div className="gallerimages">
          <div className="galleyig">
            <div onClick={() => setSmShow(true)} className="me-2">
              <img
                style={{
                  height: "12rem",
                  width: "12rem",
                  margin: "2rem",
                  border: "3px solid black"
                }}
                src="./img/r1.jpeg" alt="" />
            </div>

            <div onClick={() => setLgShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/r2.jpeg" alt="" />
            </div>
            <div onClick={() => setXsShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/r3.jpeg" alt="" />
            </div>


          </div>

          <div className="galleyig">
            <div onClick={() => setMdShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/r4.jpeg" alt="" />
            </div>
            <div onClick={() => setXmShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/r5.jpeg" alt="" />
            </div>
            <div onClick={() => setXlShow(true)}><img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black"
              }}
              src="./img/r6.jpeg" alt="" />
            </div>
          </div>
        </div>


      </div>
      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/r1.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/r2.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>

      <Modal
        size="lg"
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/r4.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      <Modal
        size="lg"
        show={xmShow}
        onHide={() => setXmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/r5.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      <Modal
        size="lg"
        show={xlShow}
        onHide={() => setXlShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/r6.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>


      <Modal
        size="lg"
        show={xsShow}
        onHide={() => setXsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{

                width: "100%", height: "100%"
              }}
              src="./img/r3.jpeg" alt="" />
          </Modal.Title>
        </Modal.Header>

      </Modal>
      {/* <ImageViewer /> */}
    </>
  );
};

export default Gallery;
