import React from "react";
import { FaMapMarkerAlt, FaHeart } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { RiFacebookFill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";


const Footer = () => {
  return (
    <>
      <div className="footer1">
        <div className="f1" data-aos="flip-right" data-aos-duration="3000">
          <div className="fleft">
            <img src="./img/Logo.png" alt="" />
          </div>
          <div className="fright">
            <h4 style={{ marginBottom: "20px" }}>Shri Ram Higher Secondary School  </h4>
            <div className="fx">
              <FaMapMarkerAlt
                style={{
                  color: "#b02c2b",
                  marginRight: "1.5rem",
                  fontSize: "1.5rem",
                }}
              />
              <p style={{ marginBottom: "20px", fontWeight: "500" }}>
                Satyagyan Colony, Chhola Dusshera Maidan, Vidisha Road , Bhopal ( MP )
              </p>
            </div>

            <div className="fy">
              <BsTelephoneFill className="tele" />
              <p style={{ marginBottom: "20px", fontWeight: "500" }}>
                8962523839 , 8358836350 , 9424485443
              </p>
            </div>
            <div className="fz">
              <a href="https://www.facebook.com/profile.php?id=100063791054216&sk=photos" target="blank">
                <RiFacebookFill
                  style={{
                    fontSize: "2rem",
                    border: "2px solid black",
                    borderRadius: "50%",
                    padding: "5px",
                    margin: "10px",
                  }}
                />
              </a>

              <a href="....">
                <RiTwitterFill
                  style={{
                    fontSize: "2rem",
                    border: "2px solid black",
                    borderRadius: "50%",
                    padding: "5px",
                    margin: "10px",
                  }}
                />
              </a>
              <a href="..." target="_blank">
                <RiYoutubeFill
                  style={{
                    fontSize: "2rem",
                    border: "2px solid black",
                    borderRadius: "50%",
                    padding: "5px",
                    margin: "10px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="f2" data-aos="flip-right" data-aos-duration="3000" style={{ marginTop: "2rem" }}>
          <iframe
            src="https://maps.google.com/maps?width=700&amp;height=400&amp;hl=en&amp;q=Satyagyan%20Colony%2C%20Chhola%20Dusshera%20Maidan%2C%20Vidisha%20Road%20%2C%20Bhopal%20+(Shri%20Ram%20Higher%20Secondary%20School%20)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed" alt="...."
            style={{ width: "100%", height: "80%", border: "0" }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="footer2">
        <div className="fa">
          <a href="/gallery">GALLERY</a>
          <a href="/about">SCHOOL INFORMATION</a>
        </div>
        <div className="fa">
          <a href="/admission">NEWS</a>
          <a href="/principal">PRINCIPAL'S DESK</a>
        </div>
        <div className="fa">
          <a href="/admission">ADMISSION</a>

          <a href="/director">DIRECTOR DESK</a>
        </div>
        <div className="fa">
          <a href="/admission">ACADEMICS</a>

          <a href="/facility">FACILITY</a>
        </div>
        {/* <div className="fa fb">
          <p>
            <b>Copyright © 2023</b><br/>
            <span style={{ fontSize: "13px" }}>
              All Rights Reserved - www.bluebellsschool.in
            </span>
          </p>
        </div> */}
      </div>
      <div className="footer3">
        <div className="policy" id="footer-policy">
          <div className="policy-row">
            <div className="policy-col">
              <p>
                Copyright © 2023 All Rights Reserved - www.ShriRamHigherSecondarySchool.in
              </p>
            </div>
            <div className="policy-col">
              <p>
                Design with{" "}
                <span>
                  <FaHeart style={{ color: "red" }} />
                </span>{" "}
                by <a href="https://maitretech.com/">maitretech.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Footer;
