// import Carousel from "react-bootstrap/Carousel";
// import * as constants from "../contants";
import Carousel from 'react-bootstrap/Carousel'
function CarouselFadeExample() {
  // const { data } = useQuery(constants.slider);

  // console.log(data, "Slider-daaataaaaa")
  return (
    <>
      <div className='baaner' style={{ marginTop: "0rem" }}>
        <Carousel>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100"
              src="img/slider1.jpeg"
              alt=""
            />
          </Carousel.Item>


          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100"
              src="img/r6_1350x500.jpeg"
              alt=""
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100"
              src="img/r3.png"
              alt=""
            />
          </Carousel.Item>
        </Carousel>
      </div>

      {/* <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active slide-4" data-bs-interval="4000">
            <img
              src="img/r3.jpeg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item active slide-4" data-bs-interval="4000">
            <img
              src="img/s.jpeg"
              className="d-block w-100"
              alt="..."
            />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> */}

    </>
    // <Carousel fade className="carousels">

    //   <Carousel.Item className="carousel1">
    //     <img
    //       className="d-block w-100"
    //       src={`http://0.0.0.0:8055/assets/${data?.Slider[0].Slider_images[0]?.directus_files_id?.id}`}
    //       alt="Second slide"
    //     />
    //   </Carousel.Item>
    //   <Carousel.Item className="carousel1">
    //     <img
    //       className="d-block w-100"
    //       src={`http://0.0.0.0:8055/assets/${data?.Slider[0].Slider_images[1]?.directus_files_id?.id}`}
    //       alt="Third slide"
    //     />
    //   </Carousel.Item>

    // </Carousel>
  );
}

export default CarouselFadeExample;
