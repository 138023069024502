import React from "react";
import ImageViewer from "./ImageViewer"

const Container3 = () => {
  return (
    <>
      <div className="heading3">
        <h4>GALLERY</h4>
      </div>
      <div className="container3" data-aos="flip-right" data-aos-duration="2000">
        <ImageViewer />
        {/* <div className="container3_right">
          <div className="imgleft">
            <img src="./img/r1.jpeg" alt="" />
            <img src="./img/r2.jpeg" alt="" />
          </div>
          <div className="imgright">
            <img src="./img/r3.jpeg" alt="" />
            <img src="./img/r4.jpeg" alt="" />
          </div>
          <div className="imgright">
            <img src="./img/r3.jpeg" alt="" />
            <img src="./img/r4.jpeg" alt="" />
          </div>
        </div> */}
      </div>
      <a
        href="/gallery"
        style={{
          display: "flex",
          justifyContent: "center",
          color: "#b02c2b",
          textDecoration: "none",
          fontSize: "1.2rem",
        }}
      >
        VIEW MORE
      </a>
    </>
  );
};

export default Container3;
